export function replaceRelationById(entities = [], relation, idKey = 'id') {
  return entities.map((item: any) => ({
    ...item,
    [relation]: item[relation][idKey],
  }));
}
export function extractRelation(entities = [], relation) {
  return entities.map((entity) => entity[relation]);
}
export function byId(entities = [], idKey = 'id') {
  const res = {};
  for (const entity of entities) {
    res[entity[idKey]] = entity;
  }
  return res;
}
export function allIds(entities = [], idKey = 'id') {
  return [...(new Set(entities.map((entity) => entity[idKey])) as any)];
}
export function scrollId(entities = [], idKey = 'scrollId') {
  return entities[idKey];
}
export function lastId(entities = [], idKey = 'lastId') {
  const lastId = entities[idKey];

  if (lastId - 1 <= 0) return null;

  return lastId - 1;
}
