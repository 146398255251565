import { byId, allIds } from '@/infra/normalizer';

type State = {
  byId: { [key: number]: any };
  allIds: string[];
  scrollId?: null | string;
  hasNoMoreData?: boolean;
};

export const defaultState: State = {
  byId: {},
  allIds: [],
  scrollId: null,
  hasNoMoreData: false,
};

export const loadSucceedReducer = <T extends State>(
  state: T,
  payload: { items: any; scrollId: string | undefined }
): State => {
  const items = payload?.items;
  const _noMoreData = items?.length === 0;

  return {
    ...state,
    byId: {
      ...state.byId,
      ...byId(items),
    },
    allIds: [...state.allIds, ...allIds(items)],
    scrollId: payload.scrollId,
    hasNoMoreData: _noMoreData,
  };
};
