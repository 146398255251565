import { createAction, createReducer } from 'redux-act';

import type { SearchEntity } from '@/store/index';
import type Search from '@/domain/search';

export const searching = createAction<ResolvedSearchinglData>(
  'search/SEARCH_TXT'
);

export default createReducer<SearchEntity<Search>>(
  {
    [searching.toString()]: (state, payload: Search) => {
      return {
        ...state,
        searchText: payload.searchText,
      };
    },
  },
  {
    searchText: '',
  }
);

export type ResolvedSearchinglData = Search;
