import { createAction, createReducer } from 'redux-act';
import { loadSucceedReducer } from '@/store/ducks/domain/mixin/itemsLoader';

// simple action creators
export const searchItemsLoadSucceeded = createAction<ResolvedData>(
  'searchItems/loadSucceeded'
);
export const searchItemsResetSucceeded = createAction(
  'searchItems/resetSucceeded'
);

export default createReducer<DomainEntity<Launch>>(
  {
    [searchItemsLoadSucceeded.toString()]: (state, payload) =>
      loadSucceedReducer<DomainEntity<Launch>>(state, payload),

    [searchItemsResetSucceeded.toString()]: () => {
      return {
        byId: {},
        allIds: [],
        scrollId: null,
      };
    },
  },
  {
    byId: {},
    allIds: [],
    scrollId: null,
  }
);

// types
import type { DomainEntity } from '@/store/index';
import type { ResolvedData } from '@/app/items/itemsGetBySearchText';
import type Launch from '@/domain/launch';
